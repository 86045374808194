footer.page-footer {
  margin-top: initial;
  .page-footer-logo {
    padding: 7% 0; }
  .page-footer-back {
    cursor: pointer;
    img {
      max-height: 50px; } }
  .footer-copyright {
    height: unset; } }
