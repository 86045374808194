.launch_now_link {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 2vh; }

.launch_now_fullscreen {
    position: absolute;
    width: 100%;
    height: 100vh; }

.launch_now_fullscreen_video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover; }

.launch_now_mute {
    position: absolute;
    top: 5vh;
    right: 2vw;

    cursor: pointer; }

.launch_now_mute_icon {
    display: none;

    &.active {
        display: block; } }
