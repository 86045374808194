@mixin imprint-font {
  font-variant: petite-caps;
  font-style: normal;
  font-size: large;
  font-weight: 300;
  .small {
    font-size: small; }
  a {
    color: $primary-color; } }

.impressum-header {
  @include big-picture-header;

  &:before {
    background: url("/assets/images/pages/imprint/imprint-header.jpg") no-repeat center center;
    background-size: cover; } }

.imprint-content {
  text-align: center; }

.imprint-content-title {
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 5vmax; }

.imprint-content-contact {
  @include imprint-font;

  margin-bottom: 4vh; }

.imprint-content-address {
  @include imprint-font;

  margin-bottom: 2vh; }



.imprint-content-tax {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 300;

  margin-top: 4vh;
  margin-bottom: 6vh; }
