.navbar-transparent {
  nav {
    background-color: transparent;
    box-shadow: none; } }

nav {
  background-color: rgba(0, 128, 158, 0.95);
  ul {
    margin-left: calc(50% - 366px);
    a {
      padding: 0 30px;
      font-size: 1.5rem;
      letter-spacing: 1px; }
    li.active {
      background-color: initial;
      font-weight: bold;
      -webkit-box-shadow: inset 0px -4px 0px 0px #40c4de;
      -moz-box-shadow: inset 0px -4px 0px 0px #40c4de;
      box-shadow: inset 0px -4px 0px 0px #40c4de; } } }

.side-nav-logo {
  margin-bottom: 20px;
  text-align: center;
  img {
    width: 80px;
    height: 80px;
    padding: 10px; }
  a:hover {
    background-color: inherit; } }

.nav-logo {
  vertical-align: middle;
  width: 65px; }

.brand-logo {
  img {
    width: 70px;
    margin-top: 8px; } }

.navbar-navigation {
  display: none;
  list-style: none;
  margin: 0;
  position: absolute;
  top: 64px;
  width: 100%;
  .navbar-item {
    text-align: center;
    width: 100%;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 300;
    background: rgba(17, 27, 36, 0.95);
    height: calc((100vh - 64px)/3);
    width: 48%;
    float: left;
    margin: 1%;
    line-height: 32px; }
  .navbar-item-title {
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    word-spacing: 200px;
    span {
      text-transform: none;
      font-weight: normal;
      word-spacing: initial; } } }

.navbar-navigation.collapsible {
  border: none;
  box-shadow: none; }

.nav-mobile-button {
  float: left;
  position: relative;
  z-index: 1;
  height: 64px;
  .nav-mobile-button-icon {
    font-size: 2.7rem;
    height: 64px;
    line-height: 64px; } }
