.contact-header {
  background: #000;
  padding: 0;
  &:before {
    @include cover_bg("/assets/images/bg/contact", center center);
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.7; }
  @media #{$medium-and-down} {
    height: auto; }
  .contact-header-container {
    padding: 20% 5%;
    z-index: 1;
    position: relative;
    @media #{$large-and-up} {
      padding: 10%; } }
  .contact-header-title {
    text-transform: uppercase;
    font-size: 8vw;
    font-weight: bold; }
  .contact-header-description {
    font-size: 26px;
    font-weight: 300; }
  .contact-header-section {
    margin-bottom: initial;
    bottom: 0;
    width: 100%;
    position: relative;
    @media #{$large-and-up} {
      position: absolute; } }
  .contact-header-box {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-bottom: none;
    border-right: none;
    position: relative;
    z-index: 1;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
      -webkit-transition: background-color 500ms linear;
      -moz-transition: background-color 500ms linear;
      -o-transition: background-color 500ms linear;
      -ms-transition: background-color 500ms linear;
      transition: background-color 500ms linear; } }

  .contact-header-box-title {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 24px;
    margin-bottom: 45px; }
  .contact-header-box-icon {
    position: relative;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -ms-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      opacity: 1; }
    .contact-header-box-icon-1, .contact-header-box-icon-2 {
      width: 50px;
      position: absolute; } } }

.contact-header-box:hover {
  .contact-header-box-icon-1 {
    opacity: 0; } }

.flipper {
  display: block;
  position: relative; }

.contact-header-box:hover {
  .back {
    -moz-transform: perspective(800px) rotateX(0);
    -webkit-transform: perspective(800px) rotateX(0);
    transform: perspective(800px) rotateX(0);
    color: #40c4de; }
  .front {
    -webkit-transform: perspective(800px) rotateX(-179.9deg);
    -moz-transform: perspective(800px) rotateX(-179.9deg);
    transform: perspective(800px) rotateX(-179.9deg);
    color: #40c4de; } }

.back, .front {
  display: block;
  white-space: nowrap;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  position: absolute;
  width: 100%; }

.back {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: perspective(800px) rotateX(180deg);
  -webkit-transform: perspective(800px) rotateX(180deg);
  transform: perspective(800px) rotateX(180deg); }

.front {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: perspective(800px) rotateX(0deg);
  -webkit-transform: perspective(800px) rotateX(0deg);
  transform: perspective(800px) rotateX(0deg); }
