.portfolio-header {
  @include big-picture-header;
  &:before {
      @include cover_bg("/assets/images/bg/portfolio", center center); } }

.portfolio-section {
  padding-top: 3%;
  .portfolio-tabs {
    height: 100px;
    .indicator {
      height: 4px; } }
  .portfolio-tab {
    line-height: 100px;
    height: 100px; }
  .portfolio-tab-icon {
    i {
      font-size: 56px;
      color: #d1d1d1; } }
  .portfolio-tab-title {
    margin-top: -70px;
    font-weight: bolder;
    color: #000000; }
  .portfolio-tab-link.active {
    .portfolio-tab-icon {
      i {
        color: #00beeb; } } } }

.portfolio-content {
  background: #fff;
  position: relative;
  z-index: 3;

  @media(max-width: 900px) {
    .portfolio-content-row {
      flex-direction: column; } }

  .portfolio-content-img {
    margin-top: 40px;
    margin-bottom: 70px; }
  .portfolio-content-intro {
    padding: 5%; }
  .portfolio-content-intro-title {
    margin: 5px 0;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2.8em;
    overflow: hidden;
    max-width: 95vw;
    @media #{$large-and-up} {
      font-size: 3.5em; } }
  .portfolio-content-intro-subtitle {
    margin: 5px 0;
    font-weight: bold;
    font-size: 1.4em;
    font-variant: small-caps; }
  .portfolio-content-intro-desc {
    margin: 5px 0;
    font-size: 1.4em;
    text-align: justify;
    @media #{$large-and-up} {
      line-height: 2; } } }


.portfolio-projects {
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -60px;
  padding-bottom: 60px;
  .no-margin-bottom {
    padding-top: 60px; }
  .portfolio-project {
    display: flex;

    @media(min-width: 1460px) {
      flex-direction: row; }

    @media(max-width: 1459px) {
      flex-direction: column; } }

  .portfolio-project-img {
    padding-left: 0;
    @media(min-width: 1460px) {
      height: 300px; }
    @media(max-width: 1459px) {
      padding-right: 0; } }
  .portfolio-project-img-file {
    @media(max-width: 1459px) {
      width: 100%; }
    @media(min-width: 1460px) {
      height: 100%; } }

  .portfolio-project-info {
    padding-right: 0;
    height: 100%;
    position: relative;

    @media(min-width: 1460px) {
      height: 300px; } }

  .portfolio-project-info-icon {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    i {
      font-size: 32px;
      color: #808080; } }

  .portfolio-project-info-icon-text {
    text-transform: uppercase;
    color: #888;
    line-height: 32px; }

  .portfolio-project-info-box {
    padding: 5%;
    padding-top: 38px; }
  .portfolio-project-info-box-title {
    text-transform: uppercase;
    font-size: 32px;
    margin: 10px 0;
    font-weight: lighter;
    span {
      border-bottom: 2px solid rgba(0, 0, 0, 0.5); } }

  .portfolio-project-info-box-button {
    background-color: #00beeb;
    margin: 10px 0; } }
