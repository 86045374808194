.philosophy-header {
  @include big-picture-header;
  &:before {
    @include cover_bg("/assets/images/bg/philosophy", center center); } }

.philosophy-section {
  padding: 5% 0;
  .philosophy-section-title {
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 7vw;
    @media #{$large-and-up} {
      font-size: 64px; } }
  .philosophy-section-subtitle {
    font-weight: bold;
    font-size: 24px; }
  .philosophy-section-des {
    line-height: 1.7;
    padding: 0 5%; } }

// .philosophy-video
//   height: 100vh
//   .philosophy-video-arrow
//     position: absolute
//     bottom: 20px
//     font-size: 50px
//     cursor: pointer
//     color: #ffffff
// video#bgvid
//   position: fixed
//   top: 50%
//   left: 50%
//   min-width: 100%
//   min-height: 100%
//   width: auto
//   height: auto
//   z-index: -100
//   -ms-transform: translateX(-50%) translateY(-50%)
//   -moz-transform: translateX(-50%) translateY(-50%)
//   -webkit-transform: translateX(-50%) translateY(-50%)
//   transform: translateX(-50%) translateY(-50%)
//   background: url(/assets/images/Kontakt4.jpg) no-repeat
//   background-size: cover
