.team-header {
  background: #000;
  &:before {
    @include cover_bg("/assets/images/bg/team", center center);
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.7; }
  .container {
    z-index: 1; } }

.team-core-title {
  font-size: 45px;
  text-transform: uppercase;
  font-weight: lighter;
  margin: 3%;
  padding-bottom: 3%; }

.team-core-first-name {
  margin-top: 5px; }

.team-core-first-name, .team-core-last-name {
  font-size: 16px;
  font-weight: bold; }

.team-core-position {
  margin-top: 5px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px; }

.team-core-partner {
  color: $secondary-color;

  &:hover {
    color: $primary-color;
    -webkit-transition: color 300ms linear;
    -moz-transition: color 300ms linear;
    -o-transition: color 300ms linear;
    -ms-transition: color 300ms linear;
    transition: color 300ms linear; } }

.team-core-partner-name {
  font-size: 18px;
  font-weight: bold; }
.team-core-img {
  object-fit: cover;
  width: 120px;
  height: 120px !important;
  @media #{$large-and-up} {
    width: 200px;
    height: 200px !important; } }

.team-core-image {
  @include cover_bg("/assets/images/team", center center);
  position: relative;
  height: 45vh;
  @media #{$large-and-up} {
    height: 75vh; } }
