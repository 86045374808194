$navbar-height: 64px !default;
$navbar-height-mobile: 64px !default;
$primary-color: #00809e !default;
$secondary-color: black;

.module {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/skyscrapers.jpg);
  background-attachment: fixed;
  width: 400px;
  height: 300px;
  position: relative;
  overflow: hidden;
  margin: 20px;
  > header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    background: inherit;
    background-attachment: fixed;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 200%;
      height: 200%;
      background: inherit;
      background-attachment: fixed;
      -webkit-filter: blur(4px);
      filter: blur(4px); }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25); }
    > h1 {
      margin: 0;
      color: white;
      position: relative;
      z-index: 1; } } }

* {
  box-sizing: border-box; }
