body {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

main {
  flex: 1 0 auto; }

.no-margin-bottom {
  margin-bottom: 0; }
