.header {
  color: #ffffff;
  margin-top: -64px;
  padding: 5%;
  height: 100vh;
  position: relative;
  .header-title {
    text-transform: uppercase;
    font-size: 7vmax;
    line-height: 7vmax;
    font-weight: bold; }
  .header-description {
    font-weight: 300;
    font-size: 20px;
    @media #{$large-and-up} {
      font-size: 26px; } }
  .header-arrow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    img {
      max-height: 80px; } } }
