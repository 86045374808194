.homepage {
  background-image: url("/assets/images/bg/homepage_2_1920.jpg");
  height: 100%;
  min-height: 100%;
  background-size: cover;
  color: #ffffff;
  overflow: hidden; }

.homepage-overley {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: none;
  top: 0;
  left: 0;
  z-index: 2; }

.homepage-logo {
  position: absolute;
  top: 5%;
  right: 3%;
  @media #{$small-and-down} {
    right: calc(50% - 85px); }
  .homepage-logo-img {
    width: 170px; } }

.homepage-wrapper {
  height: 100vh; }

.homepage-links {
  margin-left: 3%;
  width: 95%;
  &:hover .homepage-overley {
    display: block;
    &:hover {
      display: none; } }
  &:hover .homepage-link {
    color: #a6a6a6;
    &:hover {
      color: #ffffff; } }
  &:focus .homepage-link {
    color: #a6a6a6;
    &:focus {
      color: #ffffff; } }
  &:active .homepage-link {
    color: #a6a6a6;
    &:active {
      color: #ffffff; } } }

.homepage-link {
  z-index: 5;
  display: block;
  position: relative;
  color: #ffffff;
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 7vw;
  line-height: 1.3;
  @media #{$medium-only} {
    font-size: 8vw;
    line-height: 1.4; }
  @media #{$small-and-down} {
    font-size: 9vw;
    line-height: 1.8; }
  .homepage-link-tagline {
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    width: 100%;
    @media #{$large-and-up} {
      opacity: 0; } }
  &:hover .homepage-link-tagline {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1; } }

.homepage-link-tagline {
  display: block;
  position: absolute;
  bottom: -24px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  height: 50px;
  font-size: 28px;
  line-height: 35px;
  @media #{$small-and-down} {
    height: 45px;
    font-size: 14px; } }
