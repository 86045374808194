@mixin big-picture-header {
  background: #000;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.7; }
  .container {
    z-index: 1; } }




@mixin cover_bg($value, $position) {
  @media only screen and (max-width: 991px) {
    /* Small screen, non-retina */
    background: url($value + "_1440.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (max-width: 991px), only screen and (min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min-resolution: 192dpi) and (max-width: 991px), only screen and (min-resolution: 2dppx) and (max-width: 991px) {
    /* Small screen, retina, stuff to override above media query */
    background: url($value + "_1920.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (min-width: 992px) {
    /* Medium screen, non-retina */
    background: url($value + "_1920.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 992px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (min-width: 992px), only screen and (min-device-pixel-ratio: 2) and (min-width: 992px), only screen and (min-resolution: 192dpi) and (min-width: 992px), only screen and (min-resolution: 2dppx) and (min-width: 992px) {
    /* Medium screen, retina, stuff to override above media query */
    background: url($value + "_2048.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (min-width: 1300px) {
    /* Large screen, non-retina */
    background: url($value + "_2048.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1300px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (min-width: 1300px), only screen and (min-device-pixel-ratio: 2) and (min-width: 1300px), only screen and (min-resolution: 192dpi) and (min-width: 1300px), only screen and (min-resolution: 2dppx) and (min-width: 1300px) {
    /* Large screen, retina, stuff to override above media query */
    background: url($value + "_2880.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (min-width: 1600px) {
    /* Large screen, non-retina */
    background: url($value + "_2880.jpg") no-repeat $position;
    background-size: cover; }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1600px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1600px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (min-width: 1600px), only screen and (min-device-pixel-ratio: 2) and (min-width: 1600px), only screen and (min-resolution: 192dpi) and (min-width: 1600px), only screen and (min-resolution: 2dppx) and (min-width: 1600px) {
    /* Large screen, retina, stuff to override above media query */
    background: url($value + "_4096.jpg") no-repeat $position;
    background-size: cover; } }


